import Icons from "./resources/icons.svg";

interface IconProps {
  name: string;
  color: string;
  size: number;
  onClick?: () => void;
}

const Icon = ({ name, color, size, onClick }: IconProps) => (
  <svg
    onClick={() => (onClick ? onClick() : {})}
    className={`icon icon-${name}`}
    fill={color}
    width={size}
    height={size}
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);

export default Icon;
