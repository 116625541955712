import "./css/App.css";
import Navigator from "./Navigator";
import VideoSection from "./sections/VideoSection";
import AboutSection from "./sections/AboutSection";
import ContactSection from "./sections/ContactSection";

const App = () => {
  return (
    <div className="app">
      <Navigator />
			<VideoSection />
			<AboutSection />
			<ContactSection />
    </div>
  );
};

export default App;
