import "./css/Navigator.css";
import { Link } from "react-scroll";

const Navigator = () => {
  return (
    <div className="nav-bar">
			<header className="nav-header">
        <h1 className="title">Violí Violã</h1>
				<h3 className="sub-title">Brazilian Choro &amp; Bossa Nova</h3>
				<ul>
					<li>
						<Link
							activeClass="active"
							to="video-section"
							spy={true}
							smooth={true}
							duration={500}
							className="noselect"
						>
							Video
						</Link>
					</li>
					<li>
						<Link
							activeClass="active"
							to="about-section"
							spy={true}
							smooth={true}
							duration={500}
							className="noselect"
						>
							About
						</Link>
					</li>
					<li>
						<Link
							activeClass="active"
							to="contact-section"
							spy={true}
							smooth={true}
							duration={500}
							className="noselect"
						>
							Contact
						</Link>
					</li>
				</ul>
      </header>
    </div>
  );
};

export default Navigator;
