import logo from "../resources/logo512.png";
import Icon from "../Icon";

const ContactSection = () => {
  const openLink = (link: string) => window.open(link, "_blank");

  return (
    <div className="section" id="contact-section">
      <div>
        <h1>Contact</h1>
        <p>Alexander Kraglund</p>
        <p>Phone: +45 30231386</p>
        <a href="mailto: alexanderkraglund@gmail.com">
          alexanderkraglund@gmail.com
        </a>
        <div className="social-links-wrapper">
          <Icon
            onClick={() => openLink("https://www.facebook.com/violiviola")}
            name="facebook"
            size={50}
            color="black"
          />
          <Icon
            onClick={() => openLink("https://www.instagram.com/violi_viola")}
            name="instagram"
            size={50}
            color="black"
          />
          <Icon
            onClick={() =>
              openLink(
                "https://www.youtube.com/channel/UCzKAPBHQp7MzR0-f3pK92cg"
              )
            }
            name="youtube"
            size={50}
            color="black"
          />
        </div>
        <img alt="instruments" className="contact-section-image" src={logo} />
      </div>
    </div>
  );
};

export default ContactSection;
