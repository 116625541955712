import ProfilePicture from "../resources/profile-picture.jpg";

const AboutSection = () => {
  return (
    <div className="section" id="about-section">
      <div>
        <h1>About</h1>
        <p>
          Violí Violã have for more than a decade specialized in Brazilian music
          reaching a worldclass level playing Choro and Bossa Nova.
        </p>
        <p>
          They deliver authentic Brazilian Music with great passion and a hint
          of Nordic tones as well as harmonies from the two musicians’
          background in Jazz.
        </p>
				<img alt="headshot" className="about-section-image" src={ProfilePicture}/>
        <p>Alexander Kraglund: Violin, Viola, Vocal & Harmonica</p>
        <p>Jeppe Holst: Violão de 7-cordas (7-stringed guitar)</p>
      </div>
    </div>
  );
};

export default AboutSection;
