import ReactPlayer from "react-player/youtube";

const VideoSection = () => {
  return (
    <div className="section" id="video-section">
			<div className="video-wrapper">
				<ReactPlayer
					width="100%"
					height="100%"
					url="https://youtu.be/VP5S1kKLDeM"
					controls
				/>
			</div>
    </div>
  );
};

export default VideoSection;
